<template>
  <input
    ref="inp"
    type="text"
    class="cell-input"
    :value="value"
    :disabled="disabled"
    @input="$emit('input', $event.target.value)"
    @focus="keyMonitor('stop')"
    @blur="keyMonitor('start')"
    @contextmenu.stop.prevent="$emit('contextmenu', $event)"
    @keyup.esc.stop.prevent="blur"
    @keyup.enter.stop.prevent="blur"
  />
</template>

<script>
export default {
  name: "CellInput",
  props: {
    value: {
      type: [String, Number],
      default: "",
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    keyMonitor(cmd) {
      this.$root.$emit(`editor.keyboard:${cmd}`);
    },
    blur($event) {
      if ($event.target) {
        $event.target.blur();
        $event.target.parentElement.focus();
      }
    }
  }
};
</script>

<style scoped>
.cell-input {
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  margin: 0;
  background: transparent;
  line-height: inherit;
  text-align: inherit;
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  text-decoration: inherit;
}

.cell-input:focus {
  border: 0;
  outline: 0;
}
</style>
